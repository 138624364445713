import React, { useContext, useEffect, useState } from "react";
import {
  StyledBookModal2Background,
  StyledBookModal2Container,
  StyledBookModal2Form,
  StyledBookModal2FormTitle,
  StyledBookModal2Description,
  StyledBookModalFormError,
  StyledBookModalFormSubmit,
  StyledBookModalFormInputBlock,
} from "./style";
import { DataContext } from "../../contexts/DataContext";
import {
  StyledForm,
  StyledFormInput,
  StyledFormLabel,
  StyledFormSubmit,
} from "../../commons/Form";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";
import { LayoutContext } from "../../contexts/LayoutContext";
import useNewsletterListId from "../../hooks/useNewsletterListId";

export default ({
  cookieName,
  gtmEvent,
}) => {
  const { labels } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const [errorMsg, setErrorMsg] = useState(null);
  const { setModalContent } = useContext(LayoutContext);
  const { getListId } = useNewsletterListId();

  function setCookie(cname, cvalue, exhours) {
    var d = new Date();
    d.setTime(d.getTime() + (exhours * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  useEffect(() => {
    setCookie(cookieName, false, 6);
  }, [cookieName]);

  return (
    <>
      <StyledBookModal2Background src='/2020/images/book_mondadori.jpg' alt='Libro Adotta le api 3Bee e Mondadori' />
      <StyledBookModal2Container>
        <StyledBookModal2Form>
          <StyledBookModal2FormTitle>
            <span>15%</span> sul tuo<br/>
            primo alveare
          </StyledBookModal2FormTitle>

          <StyledForm
            Validate
            onSubmit={(event) => {
              event.preventDefault();
              const form_data = new FormData(event.currentTarget);

              fetch("https://api.ipify.org/")
                .then(response => response.text())
                .then(response => {
                  form_data.append("g-recaptcha-response", "token");
                  form_data.append("lang", "IT");
                  form_data.append("list_id", getListId());

                  if (gtmEvent) {
                    window.dataLayer.push({
                      "event": gtmEvent,
                    });
                  }

                  fetch("https://3bee.link:8989/api/addNewsletter", {
                    method: "POST",
                    body: form_data,
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        setCookie(cookieName, true, 10 * 365 * 24);
                        setModalContent(false);
                      } else {
                        setErrorMsg("Errore, riprova o contatta info@3bee.it");
                      }
                    })
                    .catch(function (e) {
                      setErrorMsg("Errore, contattaci a info@3bee.it");
                    });
                },
                );
              event.target.reset();
              return false;
            }}
          >
            <StyledBookModalFormInputBlock>
              <StyledFormInput
                height="30px"
                id="newsletter-book-email"
                type="email"
                name="email"
                onInput={(e) => {
                  setFormData({
                    ...formData,
                    email: e.currentTarget.value, 
                  });
                }}
              />
              <StyledFormLabel
                top = "15px"
                htmlFor="newsletter-book-email"
                isHidden={formData.email && formData.email.length > 0}
              >
                {labels.MAIL_ADDRESS}
              </StyledFormLabel>
            </StyledBookModalFormInputBlock>
            <StyledBookModalFormError>
              {errorMsg}
            </StyledBookModalFormError>
            <StyledBookModalFormSubmit>
              <StyledFormSubmit type="submit">
                Iscrivimi
                <Icon icon={icons.arrowRight} color={Colors.white} />
              </StyledFormSubmit>
            </StyledBookModalFormSubmit>
          </StyledForm>

        </StyledBookModal2Form>
        <StyledBookModal2Description>
          Nella mail riceverai in anteprima il nostro <span className='big'>libro delle api.</span><br/>
          <span className='small'>(Disponibile in tutti i negozi Mondadori)</span>
        </StyledBookModal2Description>
      </StyledBookModal2Container>
    </>
  );
};

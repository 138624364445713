import styled from "styled-components";

import {
  BP,
  Colors,
  Rem,
  Font,
} from "../../commons/Theme";
import { StyledIcon } from "../../components/Icon/style";
import { StyledFormLabel, StyledFormSubmit } from "../../commons/Form";

export const StyledBookModal2Background = styled.img`
  height: 100%;
  max-width: 90vw;
`;

export const StyledBookModal2Container = styled.div`
  position: absolute;
  top: 30%;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const StyledBookModal2Form = styled.div`
  width: 70%;
  margin: 0 auto;
  font-family: ${Font.sans};
  ${StyledFormLabel} {
    transform: translateX(${Rem(8)});
  }
`;

export const StyledBookModal2FormTitle = styled.p`
  text-align: right;
  font-family: ${Font.sans};
  color: ${Colors.white};
  padding-bottom: ${Rem(16)};
  font-size: 4vmin;
  
  & span {
    font-weight: 900;
    font-size: 7vmin;
  }
`;

export const StyledBookModal2Description = styled.p`
  background-color: ${Colors.yellow};
  font-family: ${Font.sans};
  text-align: center;
  margin-top: 4vmin;
  padding: ${Rem(10)};
  color: ${Colors.white};
  font-size: 3.5vmin;

  & .big {
    font-size: 5vmin;
  }

  & .small {
    font-size: 3vmin;
  }
`;

export const StyledBookModal = styled.div`
  display: flex;
  font-family: ${Font.sans};
  color: ${Colors.black};
  background-color: ${Colors.white};
  padding-top: ${Rem(40)};
  padding-bottom: ${Rem(50)};
  flex-direction: column;

  @media (${BP.tablet}) {
    padding-top: ${Rem(120)};
    padding-bottom: ${Rem(100)};
  }
`;

export const StyledBookModalFormError = styled.p`
  color: ${Colors.red};
`;

export const StyledBookModalFormSubmit = styled.div`
  display: flex;
  font-family: ${Font.sans};
  justify-content: right;
  padding-top: ${Rem(14)};

  ${StyledFormSubmit} {
    height: ${Rem(48)};
    padding: 0 ${Rem(32)};
    min-width: auto;
    ${StyledIcon} {
      margin-left: ${Rem(16)};
    }
  }
`;

export const StyledBookModalFormInputBlock = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${Rem(8)};
  font-family: ${Font.sans};
  &:first-child&:last-child {
    width: 100%;
  }
`;
